import getConfig from 'next/config'
import { getDeviceIsXbox } from '../hooks/useDeviceInfo'
import { Logger } from './logger'
const logger = Logger.of('videoUtils')
export const getClosedCaptionOrSubtitleTracks = (
  player: shaka.Player
): {
  textTracks: shaka.extern.Track[]
  nextTrack: shaka.extern.Track | undefined
  activeTrack: shaka.extern.Track | undefined
} => {
  const textTracks = player
    .getTextTracks()
    .filter((track) => track.kind === 'caption' || track.kind === 'subtitle')
    .sort((lhs, rhs) => lhs.kind!.localeCompare(rhs.kind!))
  const nextTrack = textTracks?.[0]
  const activeTrack = textTracks.find((track) => track.active)

  return {
    textTracks,
    nextTrack,
    activeTrack,
  }
}

/**
 * LOCAL XBOX DRM:
 * This is intended to be used for xboxes in devMode
 */

export interface CrtTemplates {
  stage: string
  prod: string
}
export interface LocalXboxDrm {
  templates: CrtTemplates
  key: string
}
export interface LocalXboxDrmRequest {
  template: string
  key: string
  token: string
}
export interface LocalXboxDrmResponse {
  token: string
}
export const modifyDRMTokenIfApplicable = async (token: string) => {
  const drm = getLocalXboxDrm()
  const isXbox = getDeviceIsXbox()
  if (!drm || !isXbox) {
    return token
  }
  const { publicRuntimeConfig } = getConfig()
  const isStage = publicRuntimeConfig?.environment === '6'
  const { templates, key } = drm
  const template = isStage ? templates.stage : templates.prod
  try {
    const response = await fetch('/api/drm', {
      method: 'POST',
      body: JSON.stringify({ token, key, template }),
    })
    if (!response.ok) {
      const error = await response.json()
      logger.warn('videoUtils > modifyDRMTokenIfApplicable: Error handling local drm orchestration for xbox', {
        error,
      })
      return token
    }
    const data = (await response.json()) as { token: string }
    return data.token ?? token
  } catch (error) {
    logger.warn('videoUtils > modifyDRMTokenIfApplicable: Error handling local drm orchestration for xbox', {
      error,
    })
    return token
  }
}

function getLocalXboxDrm(): LocalXboxDrm | undefined {
  const { publicRuntimeConfig } = getConfig()
  if (publicRuntimeConfig?.localXboxDrm) {
    return publicRuntimeConfig?.localXboxDrm
  }
  try {
    if (typeof window?.localStorage === 'undefined') {
      return undefined
    } else {
      const value = window.localStorage.getItem('localXboxDrm')
      return value ? (JSON.parse(value) as LocalXboxDrm) : undefined
    }
  } catch (_) {
    return undefined
  }
}
